.font-weight-400 {
    font-weight: 400!important;
}
.font-weight-500 {
    font-weight: 500!important;
}
.font-weight-600 {
    font-weight: 600!important;
}
.font-weight-bold {
    font-weight: bold!important;
}
.font-weight-bolder {
    font-weight: bolder!important;
}
.color-white {
    color: #fff!important;
}
.color-block {
    color: #000!important;
}
.color-gray {
    color: gray!important;
}
.padding-10 {
    padding: 10px!important;
}
.margin-0 {
    margin: 0px!important;
}
.border-0 {
    border: 0;
}
.display-none {
    display: none!important;
}
.display-flex {
    display: flex!important;
}
.clear-both {
    clear: both!important;
}

.text-align-center {
    text-align: center!important;
}
.text-align-left {
    text-align: left!important;
}
.text-align-right {
    text-align: right!important;
}

.float-left {
    float: left!important;
}
.float-right {
    float: right!important;
}
.w-auto {
    width: auto!important;
}
.w-100 {
    width: 100%!important;
}
.h-100 {
    height: 100%!important;
}
.marginTop-0 {
    margin-top: 0px!important;
}
.marginTop-5 {
    margin-top: 5px!important;
}
.marginTop-10 {
    margin-top: 10px!important;
}
.marginTop-15 {
    margin-top: 15px!important;
}
.marginTop-20 {
    margin-top: 20px!important;
}

.marginBottom-0 {
    margin-bottom: 0px!important;
}
.marginBottom-5 {
    margin-bottom: 5px!important;
}
.marginBottom-10 {
    margin-bottom: 10px!important;
}
.marginBottom-15 {
    margin-bottom: 15px!important;
}
.marginBottom-20 {
    margin-bottom: 20px!important;
}

.marginLeft-0 {
    margin-left: 0px!important;
}
.marginLeft-5 {
    margin-left: 5px!important;
}
.marginLeft-10 {
    margin-left: 10px!important;
}
.marginLeft-15 {
    margin-left: 15px!important;
}
.marginLeft-20 {
    margin-left: 20px!important;
}

.marginRight-0 {
    margin-right: 0px!important;
}
.marginRight-5 {
    margin-right: 5px!important;
}
.marginRight-10 {
    margin-right: 10px!important;
}
.marginRight-15 {
    margin-right: 15px!important;
}
.marginRight-20 {
    margin-right: 20px!important;
}

.paddingTop-0 {
    padding-top: 0px!important;
}
.paddingTop-5 {
    padding-top: 5px!important;
}
.paddingTop-10 {
    padding-top: 10px!important;
}
.paddingTop-15 {
    padding-top: 15px!important;
}
.paddingTop-20 {
    padding-top: 20px!important;
}

.paddingBottom-0 {
    padding-bottom: 0px!important;
}
.paddingBottom-5 {
    padding-bottom: 5px!important;
}
.paddingBottom-10 {
    padding-bottom: 10px!important;
}
.paddingBottom-15 {
    padding-bottom: 15px!important;
}
.paddingBottom-20 {
    padding-bottom: 20px!important;
}

.paddingLeft-0 {
    padding-left: 0px!important;
}
.paddingLeft-5 {
    padding-left: 5px!important;
}
.paddingLeft-10 {
    padding-left: 10px!important;
}
.paddingLeft-15 {
    padding-left: 15px!important;
}
.paddingLeft-20 {
    padding-left: 20px!important;
}

.paddingRight-0 {
    padding-right: 0px!important;
}
.paddingRight-5 {
    padding-right: 5px!important;
}
.paddingRight-10 {
    padding-right: 10px!important;
}
.paddingRight-15 {
    padding-right: 15px!important;
}
.paddingRight-20 {
    padding-right: 20px!important;
}
.cursor-pointer {
    cursor: pointer!important;
    user-select: none !important;
}

.SideViewScroll {
    max-width: 100%;
    /* height: calc(90vh - 80px); */
    /* overflow-y: auto; */
    padding-left: 0px;
    /* padding-right: 10px; */
    /* background-color: #fff;
    padding: 10px; */
}
.KB_ConceptTeachingVideo {
    /* border-radius: .50rem; */
    display: flex;
    overflow: hidden;
    background-color: #fff!important;
    margin-bottom: 0px;
}
.KB_videoModule {
    margin: 10px;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    border-radius: .50rem;
}
.YoutubeIframe {
    width: 100%;
    height: 100%;
}
.KB_Video_DDXC_Title {
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    cursor: pointer;
}
.KB_SSQEx_SEARCH_MODULE {
    /* position: absolute; */
    left: 10px;
    z-index: 15;
    width: 24vw;
}
.SEARCH_iyNB_Card {
    margin-top: 0px;
    border-radius: none;
    /* padding: 10px; */
    display: flex;
    overflow: hidden;
    padding-top: 0px;
    width: 24vw;
    /* max-height: 90vh; */
    margin-bottom: 0px;
    border: 3px solid rgb(0, 53, 109);
}
.SEARCH_iyNB_label {
    text-align: center;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    bottom: 0px;
    padding-left: 30px;
    float: left;
}
.SEARCH_CLqW_Close {
    float: right;
    top: 6px;
    padding: 0px;
}
.SEARCH_MDClose_Icon {
    vertical-align: initial;
    font-size: 1.2em;
    color: #fff;
}
.SEARCH_Close_Button {
    color: #00356D;
    background-color: #00356D;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.REqH_x {
    width: 100%;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.REqH_x1 {
    /* width: 385px; */
    /* background-color: #fff; */
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    /* padding: 10px; */
    margin-left: 0px;
}
.SearCh_zAqX_Inpt {
    width: 100%;
    background-color: #fff;
    height: 42px;
    display: flex;
    padding-left: 15px!important;
    padding-right: 5px!important;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: none !important;
    border-color: transparent !important;
    outline: none !important;
    border-radius: 50%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.00938em;
    color: #000;
}

.SearCh_zAqX_Inpt:focus {
    outline: none !important;
    width: 100%;
    background-color: #fff;
    height: 42px;
    display: flex;
    padding-left: 15px!important;
    padding-right: 5px!important;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: none !important;
    border-color: transparent !important;
    outline: none !important;
    border-radius: 50%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.00938em;
    color: #000;
}


.Search_Btn_yytq {
    padding: 5px;
    overflow: hidden;
    vertical-align: middle;
    color: #fff;
    background: rgb(1, 53, 110);
    margin: 1px;
    border: 3px solid rgb(1, 53, 110);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 4px;
}
.Search_Img_yytq {
    width: 20px;
    height: 20px;
}
.Sear_Res_qwmM {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* padding: 5px; */
    justify-content: space-between;
}
.zoomCard {
    width: 24vw;
    /* padding: 10px; */
    position: absolute;
    background-color: #fff;
    border: 3px solid rgb(0, 53, 109);
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 0px;
}
.Zoom_label_Mod {
    text-align: center;
    padding: 3px;
    font-size: 14px;
    font-weight: bold;
    bottom: 10px;
    padding-left: 50px;
}
.pag_LayouT_label_Mod {
    text-align: center;
    padding: 3px;
    font-size: 14px;
    font-weight: bold;
    bottom: 10px;
    padding-left: 50px;
}
.gotoP_label_Mod {
    text-align: center;
    padding: 3px;
    font-size: 14px;
    font-weight: bold;
    bottom: 10px;
    padding-left: 50px;
}
.gotoP_nbxZ_Clse {
    bottom: 20px;
    float: right;
    padding: 0px;
    top: -6px;
}
.Zoom_yyIo_Clse {
    bottom: 20px;
    float: right;
    padding: 0px;
    top: -6px;
}
.PagLay_yyIo_Clse {
    bottom: 20px;
    float: right;
    padding: 0px;
    top: -6px;
}
.gotoP_Clos_Btn {
    color: #fff;
    background-color: #00356D;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.ZooM_Clos_Btn {
    color: #fff;
    background-color: #00356D;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.PagLay_Clos_Btn {
    color: #fff;
    background-color: #00356D;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.gotoP_Mdcls_Icon {
    vertical-align: initial;
    font-size: 1.2em;
    color: #fff;
}
.ZooM_Mdcls_Icon {
    vertical-align: initial;
    font-size: 1.2em;
    color: #fff;
}
.PagLay_Mdcls_Icon {
    vertical-align: initial;
    font-size: 1.2em;
    color: #fff;
}
.ZO_reWq {
    margin-top: 7px;
    cursor: pointer;
}
.zoomtext {
    width: 40%;
    text-align: center;
    padding: 3px;
}
.ZI_MloP {
    margin-top: 7px;
    cursor: pointer;
}
.zoomDiv_yytr {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.LayoutView_PjqA {
    width: 100%;
    display: flex;
    justify-content: center;
}
.goToPage {
    width: 24vw;
    padding: 10px;
    position: absolute;
    background-color: #fff;
    border: 3px solid rgb(0, 53, 109);
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 0px;
}
.gotoPage_qwoP {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.gotoP_Prev_next_icon {
    cursor: pointer;
    margin-top: 4px;
}
.Pgoto_wWqM {
    display: flex;
    align-items: center;
}
.gotoPtext {
    width: 50px;
    padding: 3px;
}
.sideNav {
    position: absolute;
    /* height: 90vh; */
    display: flex;
    justify-content: center;
}
.SideN_YeqAq {
    /* z-index: 1000; */
    /* background-color: #eeeeee;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 35%); */
}
.wZzc_SidNav {
    margin-top: 15px;
    margin-bottom: 15px;
    transform: 0.5s;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    /* border: 3px solid  #00356D; */
    /* border: 2px solid  #00356D; */
}
.wZzc_SidNav a {
    font-weight: bolder;
    margin-left: 15px;
}
.SideModule_nav {
    position: absolute;
    left: 10px;
    z-index: 15;
}
.Book_notes_ListView {
    margin-top: 10px;
    width: 24vw;
    position: fixed;
    right: 10px;
    background-color: #fff;
}

.all_clarify_view {
    margin-top: 10px;
    position: fixed;
    right: 10px;
    background-color: #fff;
}
.BookM_Card_qwAu {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
}
.BookM_hgt {
    /* height: 90vh; */
    background-color: #fff!important;
}
.BbokM_uQaP {
    width: 100%;
    background-color: #00356D;
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    border: 1px solid white;
}
.BookM_cls_Btn {
    color: #00356D;
    background-color: #fff;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.bookM_MdCls_iCon {
    vertical-align: initial;
    font-size: 1.2em;
}
.BookM_Search_iq {
    height: 60px;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
}
.BookM_Search_txt {
    width: 90%;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
}
.BookM_Search_Btn {
    background-color: #00356D;
    height: 35px;
    color: #fff!important;
    width: 35px;
    padding: 0px;
    border-end-start-radius: 0px;
    border-start-start-radius: 0px;
}
.ttl_bookM_hgt {
    /* height: 76vh; */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 5px;
}
.Notes_hgt {
    background-color: #fff!important;
}
.Notes_NiIx {
    width: 100%;
    background-color: #00356D;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    color: #fff;
    border: 1px solid white;
}
.Notes_cls_Btn {
    color: #00356D;
    background-color: #fff;
    padding: 3px;
    height: 25px;
    float: right;
    width: 25px;
    justify-content: space-around;
    border-radius: 50px;
}
.Notes_MdCls_iCon {
    vertical-align: initial;
    font-size: 1.2em;
}
.Notes_Search_IuQw {
    height: 60px;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
}
.Notes_Search_txt {
    width: 90%;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
}
.Notes_Search_Btn {
    background-color: #00356D;
    height: 35px;
    color: #fff!important;
    width: 35px;
    padding: 0px;
    border-end-start-radius: 0px;
    border-start-start-radius: 0px;
}
.ttl_Notes_hgt {
    /* height: 76vh; */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 5px;
}
.AdditionalView {
    max-width: 100%;
    position: absolute;
    z-index: 20;
    /* height: 90vh; */
    top: 105px;
    left: 0px;
    /* padding-top: 20px;
    padding-left: 20px; */
    padding-top: 0px;
    padding-left: 0px;
}
.ClarifyView{
    max-width: 100%;
    position: absolute;
    z-index: 21;
    /* height: 90vh; */
    top: 107px;
    left: 7px;
    padding-top: 0px;
    padding-left: 0px;
}
.Addit_React_Drag {
    bottom: 0px;
    left: 0px!important;
    background-color: #fff;
    /* border-left: 1px solid #d8dbe0; */
    z-index: 50;
    top: 0px!important;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px; */
    transform: rotate(30deg);
    /* height: 88.7vh!important; */


}

.Addit_React_Not_Drag {
    bottom: 0px;
    background-color: #fff;
    /* border-left: 1px solid #d8dbe0; */
    z-index: 50;
    width: 98.5vw;
    margin-left: 10px;
    margin-top: 10px;
}
.Qapl_uIiv {
    align-items: center;
    height: 60px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #D2D2D2;
    justify-content: space-between;
    /* padding-left: 15px; */
    width: 100%;
    /* margin-bottom: 10px; */
}
.Sml_Left_yvXz {
    float: left;
    padding-left: 0px;
}
.sml_Right_iiMn {
    right: 0px;
    justify-content: space-around;
    float: right;
    padding-left: 0px;
}
.sml_Right_clsBtn_OuQw {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
    margin-right: 5%;
}
.sml_Right_ArrowBtn_p4dV {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
    margin-right: 5%;
}
.Full_Left_vQpT {
    float: left;
    padding: 5px;
    padding-left: 0px;
}
.Full_Right_87Ppq {
    right: 0px;
    justify-content: space-around;
    float: right;
    padding: 15px;
}
.Full_Right_clsBtn_BvzA12 {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
}
.Full_Right_ArrowBtn_luQaGq {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
    margin-right: 5%;
}
.AddiCont_Menu_uYqA {
    margin-top: 0px;
    position: absolute;
    z-index: 999999999999;
    background-color: #fff;
    padding: 0px!important;
    border-radius: 10px;
    font-family: 'Inter';

}
.MenuX_isOpen_rqe10Q {
    /* border: 2px solid #00356D; */
    display: flex;
    align-items: center;
    /* box-shadow: rgb(0 0 0 / 35%) 0px 2px 2px 0px; */
    width: 100%;
    /* height: 43px; */
    padding: 10px 10px 10px 15px;
}
.AddiMenu_Titlename {
    height: auto;
    user-select: none;
    /* text-align: center; */
}
.MenuX_Expand_vieW6tY {
    /* border: 2px solid #00356D; */
    display: flex;
    align-items: center;
    /* box-shadow: rgb(0 0 0 / 35%) 0px 2px 2px 0px; */
    width: 100%;
    height: auto;
    justify-content: center;
    padding: 10px 10px 10px 15px;
}
.ChevDown_Icon {
    position: center;
    padding: 0px;
}
.ChevronIcon {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.additionalData {
    max-width: 100%;
    overflow-y: scroll;
    padding-left: 0px;
    padding-right: 0px;
}
.additionalData .AddiContent_Text {
    padding: 10px;
}
.AddiContent_Text {
    background: #fff;
    display: block;
    margin: -90 auto;
    margin-bottom: 0px;
    /* padding: 20px; */
    /* padding: 50px 100px; */
    padding: 0px 100px;
    font-stretch: inherit;
    border-color: #fff;
}
.AddiContent_Text_Fold {
    background: #fff;
    display: block;
    margin: -90 auto;
    margin-bottom: 0px;
    /* padding: 20px; */
    /* padding: 50px 100px; */
    padding: 10px 100px;
    font-stretch: inherit;
    border-color: #fff;
}
.RenderVideo {
    width: 45%;
    position: fixed;
    z-index: 10;
    height: 450px;
    background-color: #fff;
    top: 0px;
    color: #00356D;
    border-color: #00356D;
    margin-top: 14%;
    margin-left: 1%;
}
.RenderVid_Panel {
    height: 6vh;
    display: flex;
    border-bottom: 1px solid #D2D2D2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #00356d;
}
.RenderPanel_Title {
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}
.AdditChatBox {
    display: flex;
    margin-top: 10px;
    padding: 0px;
}
.AdditAttachMent_uQoi {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    background-color: #00356d;
    color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}
.AdditSend_yTwQ {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    background-color: #00356d;
    color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}
.AdditChat_Right_Mod {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 10px;
    border-left: 0px;
    padding-bottom: 5px;
    max-width: 60px;
}
.AdditChat_Left_Mod {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}
.mapAttachList {
    display: flex;
    width: auto;
}
.RightIndex_Menu {
    display: flex;
    flex-direction: row;
}
.MainIndex_headPanel {
    border-top: 2px solid #00356D;
    border-bottom: 3px solid #00356D;
    color: #00356D;
    font-weight: bolder;
    margin-bottom: 13px;
    /* width: 21vw; */
    text-align: center;
    margin-left: 10px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.IndexName {
    /* width: 100%; */
    text-align: center;
}
.IndexMenuList {
    max-width: 100%;
    height: 82.5vh;
    overflow-y: auto;
    /* padding-left: 10px;
    padding-right: 10px; */
    /* width: 21vw; */

}


/* Video player style */

.controls {
    /* visibility: hidden; */
    opacity: 0.5;
    width: 345px;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    background-color: black;
    box-shadow: 3px 3px 5px black;
    transition: 1s all;
    display: flex;
  }
  button.videoTag {
    border: none;
    background-color: #fff;
}
  .videoTag:before {
    font-family: HeydingsControlsRegular;
    /* font-size: 20px; */
    position: relative;
    content: attr(data-icon);
    /* color: #aaa; */
    text-shadow: 1px 1px 0px black;
  }

  .player:hover .controls, player:focus .controls {
    opacity: 1;
  }

  .timer {
    line-height: 38px;
    font-size: 10px;
    font-family: monospace;
    text-shadow: 1px 1px 0px black;
    color: white;
    flex: 5;
    position: relative;
  }

  .timer div {
    position: absolute;
    background-color: rgba(255,255,255,0.2);
    left: 0;
    top: 0;
    width: 0;
    height: 38px;
    z-index: 2;
  }

  .timer span {
    position: absolute;
    z-index: 3;
    left: 19px;
  }


/* .IndexArrowicon {
    background: #00356D!important;
} */
.IndexSession_Part {
    margin-top: 10px;
    height: auto;
    max-height: 80px;
    display: flex;
    align-items: center;
    padding: 10px;
}
.Index_SessionName {
    text-justify: auto;
    user-select: none;
    width: 100%;
    text-align: center;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    /* min-height: 48px!important; */
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px!important;
}
.PaginationMod_UazM {
    background-color: rgb(235, 237, 239);
    padding-right: 0px;
    padding-left: 27px;
    color: white;
    z-index: 11;
}
.paginate_Prev_Icon {
    background-color: #00356D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
}
.paginateunit_YtqM {
    font-weight: bold;
    font-size: 10px;
    text-align: center;
}
.Notesmapbooks_RTqS {
    width: 100%;
    /* padding: 10px; */
    margin-bottom: 10px;
}
.ImgNote_data_uIYv {
    width: 40px;
    height: 50px;
}
.UnitName_nmxZ_Sess_VarI {
    padding: 0px 5px;
    color: #00356D;
    font-weight: bolder;
}
.NotesView_PlaQW {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
}
.notesDetail_In_view {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
}
.notesListImgIcon {
    width: 36px;
    height: 36px;
}
.NotesLength_Avail {
    width: 100%;
    padding: 0px 10px;
    border: 1px solid;
}
.newmapAttach_AdditCont {
    width: 150px;
    height: 100px;
    padding-left: 20px;
}
.clariFyAttach_List_OIhB {
    padding-left: 10px;
    height: 85px;
}
.ClarifyAttach_VideoTag_tbNx {
    height: 75px;
    width: 100px;
}
.ClarifyAttach_playTag_NvdX {
    font-size: 1.5rem;
    color: #fff;
    position: relative;
}
.ClarifyAttach_AudioTag_n97PO {
    width: 100px;
}
.ClarifyAttach_ImgTag_mxZO {
    width: 100px;
    height: 75px;
}
.ClariFy_React_Drag {
    bottom: 0px;
    right: 0px!important;
    background-color: #fff;
    /* border: 1px solid #d8dbe0; */
    z-index: 50;
    top: 0px!important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    transform: rotate(30deg);
    /* height: 88.7vh!important; */

}

.expand_View_full_chat {
    transform: translate(0px)!important;
    background-color: #fff!important;
    margin-top: 8px!important;
    width: 98.8vw!important;
}

.Small_view_res_chat {
    transform: translate(0px)!important;
    background-color: #fff!important;
    margin-top: 60px!important;
    position: fixed!important;

}

.expand_View_res_chat {
    -webkit-transform: translate(0px)!important;
    transform: translate(0px)!important;
    background-color: #fff!important;
    margin-top: 62px!important;
    width: 100vw!important;
    position: fixed!important;
}

.Clary_im90Yq {
    align-items: center;
    min-height: 60px;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #D2D2D2;
    justify-content: space-between;
    width: 100%;
}
.clarI_Full_HeadPanel {
    max-width: 90%;
}
.clarI_Smal_HeadPanel {
    color: #00356d;
    font-weight: bolder;
}
.ClarYPanel_Right {
    justify-content: space-around;
    float: right;
}
.ClarY_Right_clsBtn_ImvX {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
}
.ClarY_Right_ArrowBtn_PltR {
    color: #fff;
    background-color: #00356D;
    padding: 0px;
    height: 40px;
    width: 40px;
    justify-content: space-around;
    border-radius: 50px;
    float: right;
    margin-right: 5%;
}
.ChatList_Uybv71L {
    padding: 10px;
    height: fit-content;
}
.ClarY_Comment_Label {
    margin-left: 10px;
    cursor: pointer;
}
.ClarY_Agree_Label {
    margin-left: 10px;
    cursor: pointer;
    background-color: green;
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.ClarY_Clarity_Label {
    margin-left: 10px;
    cursor: pointer;
    background-color: #39f;
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.Reply_Module_Sec_UvoN {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.ClarY_Reply_Answer_UYvX {
    margin-left: 10px;
    cursor: pointer;
    background-color: green;
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.ClarY_Reply_Comment_uyBn {
    margin-left: 10px;
    cursor: pointer;
    background-color: #00356d;
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.ClarY_Reply_Delete_LQsN {
    margin-left: 10px;
    cursor: pointer;
    background-color: red;
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.ClarY_Reply_Edit_KjGF {
    margin-left: 10px;
    cursor: pointer;
    background-color: rgb(51 151 254);
    color: #fff;
    padding: 4px 10px;
    border-radius: 10%;
}
.ChatHistoryModule_NbxA_Ytf08I {
    width: 100%;
    color: #262626;
    overflow-y: scroll;
    cursor: auto;
    overflow-x: hidden;
}
.ClarfYAttach_7yB_IU90J {
    display: flex;
    justify-content: space-between;
}
.AttachMentClarify_JGQL5Tf {
    display: flex;
    align-content: space-evenly;
    margin-top: 5px;
    padding-left: 8px;
}
.ImgAttach_ClariUBT {
    height: 40px;
    width: 40px;
    border-radius: 10px;
}
.Commentor_Reply_86Gh {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}
.Request_Clarify {
    color: gray;
    font-size: 12px;
}
.CommentTime_PUnT {
    align-self: flex-end;
    font-size: 12px;
}
.AttachCarousel_7yNO {
    max-width: 100%;
    margin-left: 10px;
    width: 100%;
}
.keyboardArrows_Clarify_UHb97 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-items: self-end;
}
.MapKeyboard_Toggle_URf06Y {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    justify-content: space-evenly;
    padding-bottom: 10px;
}
.all_gallery_list {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 0px;
    max-height: 90px;
    cursor: auto;
}
.clarifyParent_Comment_7uWZ {
    border-bottom: 0px;
    margin: 0px;
    background-color: #FFF4E4;
}
.parent_HerderCmnt_DqoI {
    border: 0px;
    background-color: #FFF4E4;
    padding: 7px;
}
.parent_Body_yqvX {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.parentBody_clarify_UYqI {
    max-height: 70px;
    overflow-y: auto;
}
.AttachOutline_UGH7V {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    background-color: #00356d;
    color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}
.ChatSendIcon_BxzU {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    background-color: #00356d;
    color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}
.chatAttach_Send_Icon {
    display: flex;
    flex-direction: column-reverse;
}
.NotesBooksCont_w_UYvXz {
    width: 24vw;
}
.AddBookMarkCont_Mod {
    width: 24vw;
    height: 315px;
    margin-left: 0px;
    max-height: 100%;
    max-width: 100%;
    background-color: #fff!important;
}

.AddBookMarkCont_Mod1 {
    width: 24vw;
    height: 815px;
    margin-left: 0px;
    max-height: 100%;
    max-width: 100%;
}
.BookMark_RowList {
    padding-right: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0px;
}
.BookmarkPanel_Label {
    color: #fff;
    background: #01356e;
    font-weight: bold;
    height: 30px;
    position: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
}
.AddBookMark_TextArea {
    height: 200px;
    width: 100%;
    margin-left: 0px;
    padding: 10px;
}
.bookMark_YtdX_NewQ_W {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 3px;
}
.BookM_Btn_uygV {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.BookMark_Save_Btn {
    border-radius: 30px!important;
    height: 30px!important;
}
.BookMark_Cancel_Btn {
    border-radius: 30px!important;
    height: 30px!important;
    margin-right: 5%!important;
}
.KatBooks_ViewList_ynCx {
    position: absolute;
    top: 75px;
    left: 80px;
    width: 95.5%;
    height: 89vh;
    padding: 20px;
    background: #fff;
    z-index: 12;
}
.KatBook_FullBookImg {
    width: 160px;
    height: 200px;
    padding: 12px;
}
.ContentTooltip_Icon {
    /* box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%); */
    /* display: none; */
    /* top: 319px !important; */
    /* left: 40% ; */
    position: absolute !important;
    display: block;
    /* width:504px; */
}

.showResults{
    width: 500px;
    padding: 20px;
    }

.showResults > h2 > button{
background: none;
border: none;
cursor: pointer;
}

.Notestext_Tip_UgXz {
    background: #01356e;
    color: #fff!important;
    border-radius: 10px!important;
    font-weight: bold;
}
.Dictionarytext_Tip_YqmI {
    background: #01356e;
    color: #fff!important;
    border-radius: 10px;
    font-weight: bold;
}
.ToolTipBar {
    color: #fff;
    border-radius: 10px;
    font-size: 10px;
}
.BookmarkText_Tip_hbXz {
    background: #01356e;
    color: #fff!important;
    border-radius: 10px;
    font-weight: bold;
}
.HighlighterText_Tip_LmcX {
    background: #01356e;
    color: #fff!important;
    border-radius: 10px;
    font-weight: bold;
}
.ToolTip_Ok_Btn {
    background: #01356e;
    color: #fff!important;
    border-radius: 10px;
    font-weight: bold;
}
.PageContComp_yuqA {
    overflow-y: auto;
    overflow-x: auto;
    margin: 0px;
    /* background-color: #fff; */
}
.PageHeader_yHbx_GreF {
    height: 1px;
    width: 100%;
    position: sticky;
    top: 0px;
}
.ubXM_ {
    width: 100%;
    margin-left: 0px!important;
    margin-right: 0px!important;
    background-color: #fff;
}
.ViewPortion_ytvx_ {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    scroll-behavior: smooth;
}
.kbGY_qwV_ {
    /* height: 50px; */
    height: 0px;
    width: 100%;
    /* position: sticky; */
    top: 0px;
}
.MainHead_Title_UybF {
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid #d8dbdf;
}
.InterSection_LeftTitle_INcX {
    cursor: pointer;
    /* width: 77%;
    float: left;
    margin-left: 1%; */
    background-color: #4c88ff;
    color: #fff;
    padding: 20px 20px;
    margin-top: 20px;
    text-align: right;
    float: right;
    min-width: 340px;
    max-width: 340px;
    /* margin-top: 60%; */
}
.InterSection_LeftTitle_INcX_assessment{
  background-color: #4c88ff;
  color: #fff;
  padding: 20px 20px;
  text-align: right;
  float: right;
  min-width: 340px;
  max-width: 340px;
}
.InterSection_Zoom_vbHQ_ {
    cursor: pointer;
    width: 22%;
    float: right;
}
.InterSection_Zoom_vbHQ_Expand_ {
    cursor: pointer;
    width: 15%;
    float: right;
}
.InterSection_ZoomPlusA_hBzL_ {
    font-size: 22px;
    font-family: monospace;
    border: 1px solid #fff;
    width: 38px;
    text-align: center;
    border-radius: 20px;
    float: right;
    margin-right: 10%;
    height: 38px;
}
.InterSection_ZoomMinusa_ybxM_ {
    font-size: 22px;
    font-family: monospace;
    border: 1px solid #fff;
    width: 38px;
    text-align: center;
    border-radius: 20px;
    float: right;
    margin-right: 5%;
    height: 38px;
}

.InterSection_ZoomPlusA_AddiLCont_ {
    font-size: 22px;
    font-family: monospace;
    /* border: 1px solid #fff; */
    width: 40px;
    text-align: center;
    border-radius: 20px;
    float: right;
    margin-right: 2%;
    height: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.InterSection_ZoomMinusa_AddiLCont_ {
    font-size: 22px;
    font-family: monospace;
    /* border: 1px solid #fff; */
    width: 40px;
    text-align: center;
    border-radius: 20px;
    float: right;
    margin-right: 2%;
    height: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.ContentBook_Xv {
    /* width: 52vw; */
    width: 75vw;
    /* padding: 10px 0px 0px 20px; */
    /* padding: 0px 0px 0px 10px; */
    margin: 0px 0px 0px 10px;
    max-width: 100%;
}
.ContentBook_Xv_fold {
    /* width: 80vw; */
    width: 83vw;
    /* padding: 0px 0px 0px 10px; */
    margin: 0px 10px 0px 10px;
    max-width: 100%;
}

.ContentBook_Xv_Right_fold {
    /* width: 52vw; */
    width: 75vw;
    /* padding: 10px 0px 0px 20px; */
    /* padding: 0px 0px 0px 10px; */
    margin: 0px 10px 0px 0px;
    max-width: 100%;
}

.right_bar_unfold {
    width: 0vw;
}
.right_bar_fold {
    width: 24vw;
}

.ContentBIo_qa_ {
    border: none;
    background-color: #EBEDEF;
    border-color: #fff;
    margin-bottom: 0px;
}
.cont_JsV {
    display: flex;
    flex-direction: row;
}
.MainBooK_ui_Q {
    background-color: #eef2f5;
    padding: 0px;
}
.ContHgt_lpQ {
    /* height: 80vh; */
    background-color: rgb(235, 237, 239);
    padding: 0px;
}
.UnitArrowRight_YbMq_ {
    font-size: 40px!important;
    color: gray;
    cursor: inherit;
}
.UnitArrowRightActive_mB_ {
    font-size: 40px!important;
}
.UnitArrowLeft_O765T_ {
    font-size: 40px!important;
    color: gray;
    cursor: inherit;
}
.UnitArrowLeftActive_lomaQ_ {
    font-size: 40px!important;
}
.NavBar_C_QaW {
    background-color: #00356D;
    display: flex;
    flex-direction: row;
    user-select: -moz-none;
    user-select: none;
}
.NavImg_opjV {
    width: 7vw;
}
.InstName_IhqP_yt {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: 15%;
}
.lqWI65W_x {
    display: flex;
    justify-content: space-evenly;
}
.KPIcon_view_Qw {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    cursor: pointer;
}
.Hgt_50_Qw {
    height: 50px;
}
.pqNy65_Hnmq {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    /* height: 90vh; */
    background-color: #eef2f5;
}
.KpostIf_IyQ {
    position: absolute;
    right: 6px;
    top: 68px;
    z-index: 300;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}
.KpostIf_IyQb {
    position: absolute;
    left: 30px;
    bottom: 0px;
    z-index: 999;
    overflow-y: scroll;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px, rgb(0 0 0 / 14%) 0px 5px 8px 5px, rgb(0 0 0 / 12%) 0px 1px 10px
}

.Iframe_PItQ_X {
    width: 375px;
    height: 30vh;
    background-color: white;
    overflow-y: scroll;
}

.CrumB_IpQ {
    font-weight: bold;
    font-size: 1rem;
    color: #00356D;
}
.SesS_IOhXz_uq {
    font-weight: bold;
    font-size: 14px;
    /* color: #00356D; */
}
.NotesCard_OUtq {
    width: 24vw;
    height: 290px;
    margin-left: 0px;
    max-width: 100%;
    max-height: 100%;
}
.NewNotesRow_iI {
    padding-right: auto;
    max-height: 100%;
    max-width: 100%;
    margin: 0px;
}
.NotesPanel_Title_IYqb {
    color: #fff;
    background: #01356e;
    font-weight: bold;
    height: 30px;
    position: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
}
.NotesTextArea_Ou_Qw {
    height: 200px;
    width: 100%;
    margin-left: 0px;
    padding: 10px;
}
.NotesPos_UTq_PO {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 3px;
    background-color: #fff!important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.IyuqH_om_ {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.NotesSave_btn_Iyqo_ {
    border-radius: 30px!important;
    height: 35px!important;
}
.notescancel_btn_nmZx_ {
    border-radius: 30px!important;
    height: 35px!important;
    margin-right: 5%;
    width: 120px;
}
.ConceptVid_Label_Title_ihQ_ {
    font-size: 18px;
    height: 36px;
    border-top: 2px solid #00356D;
    border-bottom: 3px solid #00356D;
    color: #00356D;
    font-weight: bolder;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.teachingVid_Label_Title_NmcT_ {
    font-size: 18px;
    height: 36px;
    border-top: 2px solid #00356D;
    border-bottom: 3px solid #00356D;
    color: #00356D;
    font-weight: bolder;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.VideoModule_UYqI {
    width: 24vw;
    /* padding: 10px; */
    padding-top: 0px;
    /* margin-left: 30px; */
    /* background: #fff; */
}
.VideoModule_UYqI_fold {
    /* width: 0vw; */
    width: 7vw;
    padding: 10px;
    padding-top: 0px;
    /* margin-left: 30px; */
    margin-left: 0px;
    /* background: #ebedef; */
}
.VideoModule_UYqI_Right_Fold {
    /* width: 0vw; */
    width: 0vw;
    padding: 0px;
    padding-top: 0px;
    /* margin-left: 30px; */
    margin-left: 0px;
    /* background: #ebedef; */
}
.VidCont_PhaX {
    display: flex;
    justify-content: flex-end;
}
.KhaQ_87jhM_ {
    background-color: rgb(235, 237, 239);
    padding-left: 20px;
    /* padding-left: 27px; */
    padding-right: 0px;
    height: 35px;
}
.Nmaeq_In26_It {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.Carousel_KNwQ87_IZ {
    background: #01356e!important;
    font-weight: bold;
    font-size: 15px!important;
}

.book{
  /* min-height: 800px;   */
}

.w-DownIcon{
    width:25px
}
.CarousAttachView_KMwQ_ {
    margin: 10px;
    overflow: hidden;
    border: 2px solid #00356D;
    border-radius: 5px;
    cursor: pointer;
}
.SlideAttachImg_uiqH {
    height: 150px;
    width: 100%;
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.animatedvideo {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.AttachAuto_KNvbQ_ {
    border: 2px solid #00356D;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}
.AttachVideo_IqTh_ {
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.galImg_UYbZ_p_ {
    /* margin: 10px; */
    margin: 0px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    border: 2px solid #00356D;
    border-radius: 5px;
}
.NbxQ_iXo_ {
    padding: 5px;
    margin: 0px;
    float: left;
}
.AttacHIiMgUpl_inZp_ {
    height: 81.5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LengthImgClvQ_untO_ {
    font-weight: bolder;
    font-size: 15px;
}
.New_MvxQPath_Img_I {
    height: 81.5px;
    width: 100%;
}
.Highlight_JqR_c {
    position: fixed;
    /* border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #eee; */
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 6%);
    border-radius: 10px;
}
.Dic_MnyI {
    background-color: #fff!important;
}
.Submit_Btn_highl {
    padding-left: 10px;
    border-left: 1px solid #ccc;
}
.high_l_Ok_Btn_ouwQ {
    font-weight: bold;
    background-color: #3373e9;
    color: #fff;
    border-radius: 30px;
    margin-right: 3%;
}
.HighL_btn_Del_opmX {
    font-weight: bold;
    background-color: #dc1c1c;
    color: #fff!important;
    border-radius: 30px;
    margin-right: 3%;
}
.ContForm_MbqY {
    background-color: #fff;
    padding: 25px;
}
.Frm_M_Label_qygX {
    font-weight: bold;
    text-align: right;
    letter-spacing: 0.2px;
    color: #3c4b64;
}
.Frm_M_Label_qygX1 {
    font-weight: bold;
    text-align: right;
    letter-spacing: 0.2px;
    color: #3c4b64;
}

.video_Click_btn {
    width: 98%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: bolder;
    color: #fff !important;
    border: none;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin: 1%;
    padding: 10px 10px !important;
    text-align: center;
}

.ContSelect_ubqA {
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #3c4b64;
}
.ViewImg_Book_qvxZ {
    border: solid 2px gray;
    width: 160px;
    height: 200px;
    padding: 12px;
}
.ViewImg_Book_qvxZ:hover {
    transition: all 1s linear;
  transform: rotateY(360deg);
}
.ContHead_KBqYrE {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
    color: #4f4f4f;
    background-color: #fff;
    /* display: flex; */
align-items: center;
justify-content: space-between;
}
.ContHead_KBqYrF{
    display: flex;
    /* padding: 10px 0px; */
    border-bottom: 1px solid #ddd;
    color: #4f4f4f;
    background-color: #fff;
    align-items: center;
justify-content: space-between;
}
.ContHead_TitleN_pjnmZq {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    text-align: left!important;
}
.CreatEBtn_qhnvZ {
    background-color: #00356D;
}
.CreatEBtn_qhnvZ:hover {
    background-color: #00356D!important;
}
.DatePicker{
    /* z-index:0 !important; */
    width: 100% !important;

}
.custom-calendar{
    background-color: #00356D;
}
.BAckArrowIconstl {
    float: right;
}

.css-26l3qy-menu{
    z-index: 101 !important;
}

.react-datepicker__month-text.react-datepicker__month-5.react-datepicker__month-text--keyboard-selected {
    background-color: #1d5d90!important;
}


/* ***** Media Query Css ***** */
@media screen and (max-width: 900px) and (min-width: 300px) {
.Frm_M_Label_qygX {
    text-align: left;
}
.ContHead_KBqYrF{
    display: flex;
    flex-direction: column-reverse;
    align-items:flex-start;
justify-content:flex-start;
}
.BAckArrowIconstl {
    float: inherit;
}
.Frm_M_Label_qygX1 {
    text-align: left;
}
.ContHead_TitleN_pjnmZq {
    text-align: center;
}
}
@media screen and (max-width: 1366px) and (min-width: 1366px), (max-height: 768px) and (min-height: 768px) {
    .IndexMenuList {
      height:81.2vh
    }
    .SEARCH_iyNB_Card {
        /* max-height: 88vh; */
    }
    .KatBooks_ViewList_ynCx {
        height: 88vh;
    }
    .BookM_hgt {
        height: 89vh;
    }
    .ttl_bookM_hgt {
        /* height: 74vh; */
    }
    .Notes_hgt {
        /* height: 89vh; */
    }
    .ttl_Notes_hgt {
        /* height: 74vh; */
    }
    .Addit_React_Drag {
        /* height: 86.9vh!important; */
    }
    .ClariFy_React_Drag {
        /* height: 86.9vh!important; */
    }
    .HideAddtChat {
        height: calc(84vh - 240px)!important;
     }
     .ViewAddtChat {
        /* height: calc(104vh - 240px)!important; */
     }
     .ClarYHgtChat_A1 {
         height: calc(77vh - 250px)!important;
     }
     .ClarYHgtChat_A3 {
        height: calc(62vh - 250px)!important;
    }
    .RenderVideo {
        margin-left: 0%;
        height: 400px;
    }
  }


  @media screen and (max-width: 1360px) and (min-width: 1360px), (max-height: 768px) and (min-height: 768px) {
    .IndexMenuList {
      height:81.2vh
    }
    .SEARCH_iyNB_Card {
        /* max-height: 88vh; */
    }
    .KatBooks_ViewList_ynCx {
        height: 88vh;
    }
    .BookM_hgt {
        height: 89vh;
    }
    .ttl_bookM_hgt {
        /* height: 74vh; */
    }
    .Notes_hgt {
        /* height: 89vh; */
    }
    .ttl_Notes_hgt {
        /* height: 74vh; */
    }
    .Addit_React_Drag {
        /* height: 86.9vh!important; */
    }
    .ClariFy_React_Drag {
        /* height: 86.9vh!important; */
    }
    .HideAddtChat {
        height: calc(84vh - 240px)!important;
     }
     .ViewAddtChat {
        /* height: calc(104vh - 240px)!important; */
     }
     .ClarYHgtChat_A1 {
         height: calc(77vh - 250px)!important;
     }
     .ClarYHgtChat_A3 {
        height: calc(62vh - 250px)!important;
    }
    .RenderVideo {
        margin-left: 0%;
        height: 400px;
    }
  }
  .DatePicker__calendarArrow{
  border-color: transparent transparent #00356D transparent !important;

  }
  /* .Calendar__day.-selected{
      background-color: inherit !important;
      border: 1px solid #00356D !important;
      color: #000 !important;
  } */
  .Calendar__monthYear>*{
      padding: .2em .1em !important;
  }
  /* .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText{
    background-color: inherit !important;
    border: 1px solid #00356D !important;
    color: #000 !important;
  } */
  .serlectorComp_input__wofjo{
    padding: 2px 8px;
    width: 100%;
    height: 38px;
    border: 1px solid hsl(0,0%,70%);
    border-radius: 5px;
    outline: none;
  }
  .SelectorComp_flex{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20 40;

  }

  @media screen and (max-width: 1280px) and (min-width: 1280px), (max-height: 800px) and (min-height: 800px) {
    .IndexMenuList {
      height:81.2vh
    }
    .SEARCH_iyNB_Card {
        /* max-height: 88vh; */
    }
    .KatBooks_ViewList_ynCx {
        height: 88vh;
    }
    .BookM_hgt {
        height: 89vh;
    }
    .ttl_bookM_hgt {
        /* height: 74vh; */
    }
    .Notes_hgt {
        /* height: 89vh; */
    }
    .ttl_Notes_hgt {
        /* height: 74vh; */
    }
    .Addit_React_Drag {
        /* height: 86.9vh!important; */
    }
    .ClariFy_React_Drag {
        /* height: 86.9vh!important; */
    }
    .HideAddtChat {
        height: calc(84vh - 240px)!important;
     }
     .ViewAddtChat {
        /* height: calc(104vh - 240px)!important; */
     }
     .ClarYHgtChat_A1 {
         height: calc(77vh - 250px)!important;
     }
     .ClarYHgtChat_A3 {
        height: calc(62vh - 250px)!important;
    }
    .RenderVideo {
        margin-left: 0%;
        height: 400px;
    }
  }

  @media screen and (max-width: 1280px) and (min-width: 1280px), (max-height: 720px) and (min-height: 720px) {
    .sideNav {
      /* height:89vh */
    }
    .pqNy65_Hnmq {
    height:89vh;
    }
    .IndexMenuList {
      height:80.2vh
    }
    .SEARCH_iyNB_Card {
        /* max-height: 87vh; */
    }
    .KatBooks_ViewList_ynCx {
        height: 87vh;
    }
    .BookM_hgt {
        height: 88vh;
    }
    .ttl_bookM_hgt {
        /* height: 72vh; */
    }
    .Notes_hgt {
        /* height: 88vh; */
    }
    .ttl_Notes_hgt {
        /* height: 72vh; */
    }
    .AdditionalView {
        /* height: 89vh; */
    }
    .Addit_React_Drag {
        /* height: 85.9vh!important; */
    }
    .ClariFy_React_Drag {
        /* height: 85.9vh!important; */
    }
    .HideAddtChat {
        height: calc(83vh - 240px)!important;
     }
     .ViewAddtChat {
        /* height: calc(104vh - 240px)!important; */
     }
     .ClarYHgtChat_A1 {
         height: calc(76vh - 250px)!important;
     }
     .ClarYHgtChat_A3 {
        height: calc(60vh - 250px)!important;
    }
    .RenderVideo {
        margin-left: 0%;
        height: 370px;
    }
  }
  .Calendar{
      padding-top: 0 !important;
      border :1px solid #ccc;
      border-radius: 0 !important;
      z-index: 1000 !important;
  }
.Calendar__monthArrow{
    border-radius: 50%;
    transition: var(--animation-duration) transform;
    pointer-events: none;
    background-repeat: no-repeat;
    display: block;
    width: 1.7em;
    height: 1.7em;
    background-size: 100% 100%;
    background-image: url(../../assets/icons/up-chevron-white.png) !important;
}
.Calendar__header {
    background-color: #00356D !important;

}
.Calendar__monthText{
    color: #fff !important;
}
.Calendar__yearText{
    color: #fff !important;

}
.Calendar__monthText:hover{
    background-color: #00356D !important;

}
.Calendar__yearText{
    background-color: #00356D !important;
}
.Calendar__weekDays{
padding-top: 1em !important;
color: rgba(0, 130, 252, 0.925) !important;
}
.Calendar{
    min-height: 22.7em !important;
}
.Calendar__sectionWrapper {
    min-height: 22.7em !important;

}
  @media screen and (max-width: 800px) and (min-width: 800px), (max-height: 600px) and (min-height: 600px) {
    .sideNav {
        /* max-height: 86vh; */
        overflow-x: hidden;
        overflow-y: auto;
    }
    .pqNy65_Hnmq {
    height:86vh;
    }
    .IndexMenuList {
      height:74.2vh
    }
    .PageContComp_yuqA {
        /* height: calc(80.6vh - 80px)!important; */
      }
    .SEARCH_iyNB_Card {
        /* max-height: 87vh; */
    }
    .KatBooks_ViewList_ynCx {
        height: 87vh;
    }
    .BookM_hgt {
        height: 88vh;
    }
    .ttl_bookM_hgt {
        /* height: 72vh; */
    }
    .Notes_hgt {
        /* height: 88vh; */
    }
    .ttl_Notes_hgt {
        /* height: 72vh; */
    }
    .AdditionalView {
        /* height: 89vh; */
    }
    .Addit_React_Drag {
        /* height: 85.9vh!important; */
    }
    .ClariFy_React_Drag {
        /* height: 85.9vh!important; */
    }
    .HideAddtChat {
        height: calc(83vh - 240px)!important;
     }
     .ViewAddtChat {
        /* height: calc(104vh - 240px)!important; */
     }
     .ClarYHgtChat_A1 {
         height: calc(76vh - 250px)!important;
     }
     .ClarYHgtChat_A3 {
        height: calc(60vh - 250px)!important;
    }
    .RenderVideo {
        margin-left: 0%;
        height: 370px;
    }
  }
.optionIconContainer:hover{
cursor: pointer;
}

.ChatOptionsContainer{
    width: 52px;
    transition: 0.3s ease;
    position: absolute;
    top: 132px;
    right: 28px;
}
.ChatElement{
    width: 50;
    height: 50;
    border-radius: 50;
    box-shadow: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.execise > p{
    display: contents !important;
    line-height: 38px !important;
    font-size: 14px !important;
}

.execise > p >span{
    font-size: 14px !important;
}

input[type="radio"] > p{
    display: contents !important;
    font-size: 12px !important;
}

.mat-input-element {
    font: inherit;
    background: 0 0;
    color: currentColor;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
}
.videoPlayer{
    height: 30px;
    position: absolute;
    bottom: 44px;
    max-width: max-content;
    min-width: 100%;
    display: flex;
    align-items: center;

}
.loader_video{
    display: grid;
    place-items: center;
    position: relative;
    top: -159px;
}
.video_progress_bar{
        /* background-color: #fff; */
        border-radius: 20px;
        /* position: relative; */
        /* margin: 15px 0; */
        height: 5px;
        width: 100% !important;
        margin: 0px !important;
}

.progress_video_container{
width: 50%;
margin-left: 25px;
margin-right: 25px;
}
.skip{
    width: 24px;
}
.color-EEF2F5{
    color: #EEF2F5 !important;
  }
.mob-display-contents-none{
  display : contents ;
}

.color-FC7755{
  color: #FC7755 !important;
}

.hidsadaslj .modal-content{
  border: none !important;
  margin:20px !important;
}
.hidsadaslj .modal-dialog{
  margin-left: 0 !important;
}
